function addEventListenerOnce (target, type, listener, addOptions, removeOptions) {
    target.addEventListener(type, function fn () {
        target.removeEventListener(type, fn, removeOptions);
        listener.apply(this, arguments);
    }, addOptions);
}

function appendStyles () {
    const $deferredStylesNode = document.getElementById('deferred-styles');
    const head = document.getElementById('head');
    const customEvent = new Event('cssLoaded');
    let cssSource = '';

    if ($deferredStylesNode) {
        // get source of css
        const matches = $deferredStylesNode.textContent.match(/href="(.*?)"/);
        if (matches !== null) {
            cssSource = matches[1];

            // append style to head
            const $link = document.createElement('LINK');

            addEventListenerOnce($link, 'load', function () {
                window.dispatchEvent(customEvent);
            });

            $link.rel = 'stylesheet';
            $link.href = cssSource;
            head.appendChild($link);
            // remove no script element
            $deferredStylesNode.parentElement.removeChild($deferredStylesNode);
        }
    }
}

function loadCss () {
    console.log('start load css');

    const raf = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

    if (raf) {
        raf(function () {
            window.setTimeout(appendStyles, 0);
        });
    } else {
        window.addEventListener('load', appendStyles);
    }
}

export { loadCss };
