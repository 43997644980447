/**
 * usage:
 * in template:
 * <button data-download>track me</button>
 *
 * in js:
 * via eventListener: bindTrackingClick($content);
 *
**/

const bindTrackingClick = ($content) => {
    const $trackingElements = $content.querySelectorAll('[data-download]');

    const addEvent = ($trackingElement) => {
        $trackingElement.addEventListener('click', () => {
            const trackingData = getTrackingData($trackingElement);

            if (trackingData !== false) {
                sendTracking(trackingData);
            }
        });
    };

    for (let i = 0; i < $trackingElements.length; i++) {
        const $trackingElement = $trackingElements[i];
        addEvent($trackingElement);
    }
};

const getTrackingData = ($trackingElement) => {
    const trackingFile = $trackingElement.getAttribute('data-download');

    const trackingData = {};
    trackingData.category = 'download';
    trackingData.file = trackingFile;

    return trackingData;
};

const sendTracking = (data) => {
    if (typeof _satellite !== 'undefined') { // eslint-disable-line
        _satellite.track(data.category, data.file); // eslint-disable-line
    } else {
        console.info('Tracking not available');
    }
};

export {
    bindTrackingClick,
    sendTracking,
    getTrackingData
};
