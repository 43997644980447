import './app.scss';
import domready from 'domready';
import { loadCss } from './utilities/js/default-css-loader';
import 'lazysizes';
import { ResizeHandler } from './utilities/js/resize-handler';
import { EventScroller } from './utilities/js/event-scroller';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { bindTrackingClick } from './utilities/js/tracking';
import { loadYoutubeApi } from './utilities/js/helper';
import { Anchor } from './components/anchor/anchor';

// import libs and components

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

const eventScroller = new EventScroller();
const resizeHandler = new ResizeHandler();

/**
 * define function for initialising modules
 */

const initContent = ($content) => {
    console.log('start app');

    const checkAdobeTracking = () => {
        const waitInterval = setInterval(() => {
            if (typeof _satellite !== 'undefined') { // eslint-disable-line
                _satellite.track("pageView", pageData); // eslint-disable-line
                bindTrackingClick($content);
                clearInterval(waitInterval);
            }
        }, 300);
    };

    function copyToClipboard () {
        const $copyButton = document.querySelector('[data-copy-clipboard]');

        if ($copyButton) {
            $copyButton.addEventListener('click', (e) => {
                const input = document.body.appendChild(document.createElement('input'));
                input.style.position = 'absolute';
                input.value = location.href;
                input.focus();
                input.select();
                document.execCommand('copy');
                input.parentNode.removeChild(input);
            });
        }
    }

    copyToClipboard();
    loadLazyImages();

    if (!document.body.classList.contains('is--editmode')) {
        checkAdobeTracking();
    }

    const $anchors = $content.querySelectorAll('a[href^="#"], a[href*="#"]:not([target="_blank"])');
    for (let i = 0; i < $anchors.length; i++) {
        const anchorAPI = new Anchor($anchors[i]);
        $anchors[i].API = anchorAPI;
    }

    // Tiles Preview (Slider for max 3 Tiles)
    const $tilesTeasers = $content.querySelectorAll('[data-tiles-teaser="root"]');
    if ($tilesTeasers.length > 0) {
        import('./areas/tiles-teaser/tiles-teaser').then(({ TilesTeaser }) => {
            for (let i = 0; i < $tilesTeasers.length; i++) {
                const $tilesTeaser = new TilesTeaser($tilesTeasers[i], {
                    resizeHandler: resizeHandler
                });
                $tilesTeaser.initialize();
            }
        });
    }

    // Tiles Preview - Masonry - Overview
    const $tilesPreviews = $content.querySelectorAll('[data-tiles-preview="root"]');
    if ($tilesPreviews.length > 0) {
        import('./areas/tiles-preview/tiles-preview').then(({ TilesPreview }) => {
            for (let i = 0; i < $tilesPreviews.length; i++) {
                const $tilesPreview = new TilesPreview($tilesPreviews[i], {
                    resizeHandler: resizeHandler
                });
                $tilesPreview.initialize();
            }
        });
    }

    // Google AutoComplete
    // const $googleLocationInputs = document.querySelectorAll('[data-google-location]');
    //
    // if ($googleLocationInputs.length > 0) {
    //     import('./utilities/js/google-autocomplete').then(({ GoogleAutoComplete }) => {
    //         for (let i = 0; i < $googleLocationInputs.length; i++) {
    //             new GoogleAutoComplete($googleLocationInputs[i]); // eslint-disable-line
    //         }
    //     });
    // }

    // Search Map Teaser
    const $searchMapTeaser = document.querySelector('[data-search-map-teaser="root"]');

    if ($searchMapTeaser) {
        import('./areas/search-map-teaser/search-map-teaser').then(({ SearchMapTeaser }) => {
            new SearchMapTeaser($searchMapTeaser); // eslint-disable-line
        });
    }

    // Contact Search (part of contacts modul)
    const $contactSearch = document.querySelector('[data-contact-search="root"]');

    if ($contactSearch) {
        import('./components/contact-search/contact-search').then(({ ContactSearch }) => {
            new ContactSearch($contactSearch); // eslint-disable-line
        });
    }

    // Search Entry
    const $searchEntry = document.querySelector('[data-search-entry="root"]');

    if ($searchEntry) {
        import('./areas/search-entry/search-entry').then(({ SearchEntry }) => {
            new SearchEntry($searchEntry); // eslint-disable-line
        });
    }

    // Initialize Filters
    const $filters = $content.querySelectorAll('[data-filters="root"]');
    if ($filters.length > 0) {
        import('./components/filter/filter').then(({ Filter }) => {
            for (let i = 0; i < $filters.length; i++) {
                const $filter = new Filter($filters[i], {
                    resizeHandler: resizeHandler
                });
                $filter.initialize();
            }
        });
    }

    // Media videos
    const $medias = $content.querySelectorAll('[data-media="root"]');
    if ($medias.length > 0) {
        loadYoutubeApi();

        window.onYouTubeIframeAPIReady = () => {
            import('./components/media/media').then(({ Media }) => {
                for (let i = 0; i < $medias.length; i++) {
                    const $media = $medias[i];
                    $media.API = new Media($media);
                }
            });
        };
    }

    const $mediasVimeo = $content.querySelectorAll('[data-media-vimeo="root"]');
    if ($mediasVimeo.length > 0) {
        import('./utilities/js/vimeo-player').then(({ VimeoPlayer }) => {
            for (let i = 0; i < $mediasVimeo.length; i++) {
                const $mediaVimeo = new VimeoPlayer($mediasVimeo[i]);
                $mediaVimeo.initialize();
            }
        });
    }

    // Image Slider
    const $imageSliders = $content.querySelectorAll('[data-image-slider="root"]');
    if ($imageSliders.length > 0) {
        import('./areas/image-slider/image-slider').then(({ ImageSlider }) => {
            for (let i = 0; i < $imageSliders.length; i++) {
                const $imageSlider = new ImageSlider($imageSliders[i]);
                $imageSlider.initialize();
            }
        });
    }

    // Media Preview
    const $mediaPreviews = $content.querySelectorAll('[data-media-preview="root"]');
    if ($mediaPreviews.length > 0) {
        import('./areas/media-preview/media-preview').then(({ MediaPreview }) => {
            for (let i = 0; i < $mediaPreviews.length; i++) {
                const $mediaPreview = new MediaPreview($mediaPreviews[i]);
                $mediaPreview.initialize();
            }
        });
    }

    // ToolTips
    const $tooltips = $content.querySelectorAll('[data-tooltip]');
    if ($tooltips.length > 0) {
        import('./components/tooltip/tooltip').then(({ Tooltip }) => {
            for (let i = 0; i < $tooltips.length; i++) {
                const $info = $tooltips[i];

                new Tooltip($info, { // eslint-disable-line
                    content: $tooltips[i].getAttribute('aria-label')
                });
            }
        });
    }

    const $searchForms = $content.querySelectorAll('[data-searchform="root"]');
    if ($searchForms.length > 0) {
        import('./components/search-form/search-form').then(({ SearchForm }) => {
            for (let i = 0; i < $searchForms.length; i++) {
                const $searchForm = $searchForms[i];
                const searchFormAPI = new SearchForm($searchForm);
                $searchForm.API = searchFormAPI;
            }
        });
    }

    // Facts
    const $$facts = $content.querySelectorAll('[data-facts="root"]');
    if ($$facts.length > 0) {
        import('./areas/facts/facts').then(({ Facts }) => {
            for (let i = 0; i < $$facts.length; i++) {
                const $facts = new Facts($$facts[i], {
                    resizeHandler: resizeHandler
                });
                $facts.initialize();
            }
        });
    }
};

/**
 * starting point
 */

domready(function () {
    window.addEventListener('cssLoaded', function () {
        initContent(document.querySelector('body'));

        const $siteHeader = document.querySelector('[data-siteheader="root"]');
        if ($siteHeader) {
            import('./layout/siteheader/siteheader').then(({ SiteHeader }) => {
                $siteHeader.API = new SiteHeader($siteHeader, {
                    eventScroller: eventScroller
                });
            });
        }

        const $backToTop = document.querySelector('[data-backtotop="root"]');
        if ($backToTop) {
            import('./components/back-to-top/back-to-top').then(({ BackToTop }) => {
                new BackToTop($backToTop, { // eslint-disable-line
                    eventScroller: eventScroller
                });
            });
        }

        eventScroller.init();
        resizeHandler.init();
    });

    loadCss();
});
